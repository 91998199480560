<template>
  <div id="app">
    <!--<transition name="fade">-->
        <keep-alive :include="keeps">
          <router-view></router-view>
        </keep-alive>
     <!--</transition>-->
  </div>
</template>

<script>
import enquireJs from 'enquire.js'
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      keeps: ['Index', 'Showroom', 'Agreement', 'Privacy'],
    }
  },
  methods: {

  },
  created () {
      const that = this
      enquireJs.register('only screen and (max-width: 767.99px)', {
          match: function () {
              that.$store.commit('toggle_mobile', true)
          },
          unmatch: function () {
              that.$store.commit('toggle_mobile', false)
          }
      })
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  width: 100%;
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
}
</style>
