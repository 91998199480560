import {removeToken, saveLoginInfo, getLoginInfo, clearLoginInfo} from '@/utils'
import {_user_info} from '@/service/api'
import request from '@/service/request'
export default {
    state :{
        userInfo: getLoginInfo(),
    },
    mutations :{
        set_user_info(state, info){
            state.userInfo = info
            saveLoginInfo(info)
        },
        clear_login_info(state){
            state.userInfo = {
                avatar: '',
                nickname: '',
                username: '',
            }
            clearLoginInfo()
            removeToken()
        },
    },
    actions :{
        getUserInfo({commit}){
            return new Promise((resolve, reject) => {
                request(_user_info, {}).then(res => {
                    console.log('getUserInfo', res)
                    commit('set_user_info', res.data)
                    resolve(res.data)
                }).catch(error => {
                    commit('clear_login_info',{})
                    reject(error)
                })
            })
        }
    }
}
