function Api(method, path, cache = false){
    let api = new Object
    api.cache = cache;
    api.method = method;
    api.path = path;
    api.permit = function(){
        return this.method + '@' + api.path
    };
    return api;
}
module.exports = {
    _user_info: Api('get','/user/info'),
    _trends_searchByFilter: Api('get','/trends/searchByFilter'),
    _trends_getTrendsById: Api('get','/trends/getTrendsById'),
}
