import {baseConfig} from '@/config'
export default {
    state :{
        baseConfig: baseConfig,
        isMobile: false,
    },
    mutations :{
        set_base_config: (state, baseConfig) => {
            state.baseConfig = baseConfig
        },
        toggle_mobile: (state, isMobile) => {
            state.isMobile = isMobile
        },
    },
    actions :{
        setBaseConfig({ commit }, baseConfig) {
            commit('set_base_config', baseConfig)
        }
    }
}
