import Vue from 'vue'

import router from './router'
import store from './store'
import Vant from 'vant'
import { Lazyload } from 'vant';
import 'vant/lib/index.css'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import "ol/ol.css"
import './assets/scss/app.scss'
import './icons'
import App from './App.vue'
import axios from './service/axios'
import apis from './service/api'
import './utils/date.js'

document.OLZ = 0

Vue.config.productionTip = false

Vue.prototype.$apis = apis
document.addEventListener('UniAppJSBridgeReady', function() {
  // eslint-disable-next-line
  Vue.prototype.$uni= uni
  /*Vue.prototype.$uni.webView.getEnv(function (res) {
    console.log('当前环境：' + JSON.stringify(res))
  })*/
})

Vue.use(Vant)
Vue.use(VueVirtualScroller)
Vue.use(Lazyload)
const app = new Vue({
  router,
  store,
  render: h => h(App),
  created () {
  },
  beforeCreate(){
    Vue.prototype.$bus = this   //配置全局总线，bus有总线的意思
  },
  mounted () {
  }
})
axios(app)
app.$mount('#app')
