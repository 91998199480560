const baseConfig = {
  appName: '',
  appLogo: require('@/assets/images/logo.png'),
  defAvatar: require('@/assets/images/avatar.png'),
  copyright: '©柯讯科技',
  defaultMapCenter: [115.816587, 28.637234],
}

module.exports = {
  baseConfig
}
