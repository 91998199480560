const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: 'hi~', auth: false}
    },
    {
        path: '/showroom',
        name: 'showroom',
        component: () => import('@/views/showroom/Index'),
        meta: { title: '精彩话圈', auth: false}
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/views/anyone/Agreement'),
        meta: { title: '用户协议', auth: false}
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/anyone/Privacy'),
        meta: { title: '隐私政策', auth: false}
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/error/NotFound'),
        meta: { title: '404' }
    },
    {
        path: '*',
        redirect: { name: '404' }
    }
];
export default routes
